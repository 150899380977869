<template>
	<div >
        <myheader></myheader>
		<div class="usermain">
			<div class="main">
				<div class="title">
                    <p>首页</p>
                    
                    <img src="../../assets/other/right.png" alt="">
                    <p class="active">会员说明</p>
                </div>
                <div class="user">
                    <div class="rights">
                        <div class="rightscard">
                           <div>
                                <img :src="info.avatar" style="">
                           </div>
                           <p>Hi，{{info.account}}</p>
                           <p>
                               <img :src="vipimg[info.level-1]" style="width:75px;height:22px" >
                           </p>
                           <p>等级积分 <span>{{info.level_integral}}</span></p>
                        </div>
                        <div class="hy">
                            <div class="bar">
                              
                                <div :class="'b'+(i+1)" v-for="(item,i) in levellist" :key="i" >
                                   <p>{{item.start_integral}}</p>
                                   <div :class="info.level==item.grade ? 'choesbg' : ''"></div>
                                   <p>{{item.mark}}</p>
                               </div>
                            </div>
                        </div>

                    </div>
                    <div class="text" v-html="content">
                        
                    </div>

                </div>
			</div>
		</div>

		<myfooter></myfooter>
		<myright></myright>
		
	</div>
</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'
import {aboutAPI,infoUserAPI} from "@/api/footnav"
export default {

  components: {
    myheader, myfooter, myright
  },
  data () {
    return {
         content:"",
            name:"",
            i:10,
            info:"",
            levellist:[{id: 1, grade: 1, mark: "普通", start_integral: 0, end_integral: 499},
                    {id: 2, grade: 2, mark: "白银", start_integral: 500, end_integral: 2499},
                    {id: 3, grade: 3, mark: "黄金", start_integral: 2000, end_integral: 9999},
                    {id: 4, grade: 4, mark: "铂金", start_integral: 7000, end_integral: 29999},
                    {id: 5, grade: 5, mark: "钻石", start_integral: 15000, end_integral: 1000000}],
            vipimg:[]
        }
    },
    created(){
        this.getabout()
        this.getinfo()
         this.getImage()
    },
    methods:{
        getImage() {
		for (let index = 1; index < 6; index++) {
			this.vipimg.push(require('../../assets/vip/vip' +index+ '.png'))			
		}
	  },
        getabout(){
            aboutAPI().then(res=>{
                this.content=res.cateList[this.i].content
                this.name=res.cateList[this.i].name
            })
        },
        getinfo(){
            infoUserAPI().then(res=>{
                this.info=res.user
                // this.levellist=res.user_level_config
            })
        },
    }
}
</script>

<style lang="scss">
.usermain{
	background-color: #f9f9f9;
	padding-bottom: 20px;
	padding-top: 10px;
	.main{
		width: 1200px;
		margin:0px auto;
		
	}

}
.title{
	display: flex;
	align-items: center;
	margin: 5px 0;

	p{
		font-size: 14px;
		color: #666666;
		line-height: 21px;
	}
	.active{
		color: #FF7C00;
	}

	img{
		width: 10px;
		height: 11px;
		margin: 0 10px;
	}
}
.user{
    padding-top: 10px;
    .rights{
        width: 1200px;
        height: 240px;
        background: linear-gradient(266deg,#FFF4E4 9%, #ffe2c7 86%);
        border-radius: 6px;
        display: flex;
        align-items: center;
        .rightscard{
            width: 390px;
            height: 165px;
            background: url(../../assets/rights.png) no-repeat;
            margin-top: 20px;
            margin-left: 30px;
            position: relative;
            text-align: center;
            div{
                position: absolute;
                top: -30px;
                left: 163px;
                width: 64px;
                height: 64px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                border-radius: 50%;
                img{
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;                      
                }
            }
            p:nth-child(2){
                margin-top: 40px;
                font-weight: 600;
                color: #6d380c;
                line-height:35px;
            }
            p:nth-child(4){
                margin-top: 20px;
                color: #6d380c;
                line-height:35px;
                span{
                    color: #FF7C00;
                    margin-left: 5px;
                }
            }
        }
        .hy{
            margin-left: 60px;
            .bar{

                position: relative;
                height: 140px;
                width:600px ;
                border-bottom: 2px solid #6d380c;
                >div{
                    margin: 0 20px;
                    position: absolute;
                    bottom:-40px;
                    >div{
                        width:52px;
                        background-color:#ffd19b;
                    }
                    .choesbg{
                        background: linear-gradient(90deg,#ff9516, #ff5800);
                    }
                    p{
                        font-weight: 600;
                        color: #333333;
                        font-size: 14px;
                        line-height: 40px;
                        text-align: center;
                    }
                    p:nth-child(3){
                        line-height: 40px;
                        font-weight: 400;
                        color: #333333;
                        font-size: 13px;
                        
                    }
                }
                .b1{
                    div{                          
                        height: 24px;                           
                    }
                    left: 30px;
                }
                .b2{
                    div{
                        height: 55px;                          
                    }
                    left: 140px;
                }
                .b3{
                    div{
                    height: 83px;
                    }
                        left: 250px;
                }
                .b4{
                    div{
                    height:108px;
                    }
                        left: 360px;
                }
                .b5{
                    div{
                    height:140px;
                    }
                        left: 470px;
                }
            }
        }
        }
        .text{
            min-height: 500px;
            background-color: #fff;
            border-radius: 5px;
            margin-top: 20px;
            padding: 10px;
        }
}

</style>
